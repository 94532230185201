import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Styles from './BreadcrumbReactstrap.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// const BreadcrumbReactstrap = () => {
//   return (
//     <Breadcrumb className={Styles.breadcrumb}>
//       <Breadcrumb.Item
//         active
//         href="#"
//         className={`${Styles.breadcrumb_item} ${Styles.active_breadcrumb_item}`}
//       >
//         Write
//       </Breadcrumb.Item>
//       <Breadcrumb.Item href="#" className={`${Styles.breadcrumb_item} `}>
//         Settings
//       </Breadcrumb.Item>
//       <Breadcrumb.Item href="#" className={`${Styles.breadcrumb_item} `}>
//         Publish
//       </Breadcrumb.Item>
//       <Breadcrumb.Item href="#" className={`${Styles.breadcrumb_item} `}>
//         Promote (Optional)
//       </Breadcrumb.Item>
//     </Breadcrumb>
//   );
// };

// export default BreadcrumbReactstrap;

const BreadcrumbReactstrap = () => {
  return (
    <Breadcrumb className={Styles.breadcrumb}>
      <Breadcrumb.Item
        active
        href="#"
        className={`${Styles.breadcrumb_item} ${Styles.active_breadcrumb_item}`}
      >
        Write
      </Breadcrumb.Item>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-settings">Coming Soon</Tooltip>}
      >
        <Breadcrumb.Item href="#" className={Styles.breadcrumb_item}>
          Settings
        </Breadcrumb.Item>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-publish">Coming Soon</Tooltip>}
      >
        <Breadcrumb.Item href="#" className={Styles.breadcrumb_item}>
          Publish
        </Breadcrumb.Item>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-promote">Coming Soon</Tooltip>}
      >
        <Breadcrumb.Item href="#" className={Styles.breadcrumb_item}>
          Promote
        </Breadcrumb.Item>
      </OverlayTrigger>
    </Breadcrumb>
  );
};

export default BreadcrumbReactstrap;
