import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getAvailableIntegrations = () => {
  return getRequest('integrations/available');
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const postNewIntegration = (type, body) => {
  return postRequest(`integrations/${type}`, body);
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
export const updateIntegration = (type, id, body) => {
  return putRequest(`integrations/${type}/${id}`, body);
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
export const patchIntegration = (type, id, param, body) => {
  return patchRequest(`integrations/${type}/${id}/${param}`, body);
};

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
export const deleteIntegration = (type, id) => {
  return deleteRequest(`integrations/${type}/${id}`);
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const publishDocuments = (documentId, payload) => {
  return postRequest(`documents/${documentId}/publish`, payload);
};
