import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FiServer, FiZap } from 'react-icons/fi';

import BSButton from 'Components/Button/BSButton';
import Styles from './../Editor.module.scss';
import { ROUTES } from 'constants/routes';
import { updateDocument } from 'services/documentService';
import { StatusOptions } from 'Components/Status/StatusOption';
import { circularProgressbarStyle } from 'styles/score';
import { CircularProgressbar } from 'react-circular-progressbar';
import { BsCloudCheck } from 'react-icons/bs';
import VerticalSeparator from 'Components/Separator/VerticalSeparator';
import { getScoreInfo } from 'helpers/score';
import BreadcrumbReactstrap from 'Components/BreadcrumbReactstrap/BreadcrumbReactstrap';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';

const SelectStyles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      boxShadow: isFocused ? null : null,
      backgroundColor: isSelected ? '#e5e5e5' : '#fff',
      background: isSelected ? `url("./Images/tickDark.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',
      height: '100%',
      padding: '6px 10px',

      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};

function EditorHeader({
  document,
  docHealth,
  aiTitle,
  lastUpdated,
  showDocHealth,
  shouldShowContentSettings,
  isComment,
  setShowTemplateDrawer,
  briefId,
  setShowBriefDrawer,
  contentSidebarCollapse,
}) {
  const [status, setStatus] = useState(document?.status || '');

  const { documentId } = useParams() || {};

  const navigate = useNavigate();

  useEffect(() => {
    if (
      aiTitle &&
      (!document.title || document.title === 'Untitled Document')
    ) {
      updateDocument(documentId, { title: aiTitle });
    }
  }, [aiTitle]);

  const handleChange = async (value) => {
    setStatus(value);
    await updateDocument(documentId, { status: value });
  };

  useEffect(() => {
    showDocHealth();
  }, []);
  
  const { overallScore } = getScoreInfo(docHealth);

  return (
    <div className="position-relative">
      <section className="document-header">
        <div className="d-flex align-items-center gap-4 flex-grow-1">
          {/* {!isComment && (
            <Select
              isSearchable={false}
              classNamePrefix="headerSelect"
              className="headerSelect"
              styles={SelectStyles}
              options={StatusOptions}
              value={StatusOptions.find((option) => option.value === status)}
              onChange={({ value }) => handleChange(value)}
            />
          )} */}
          <div className={Styles.documentStatusWrapper}>
            <div
              className={`last-changed ${contentSidebarCollapse ? 'flex-d-column' : 'flex-d-row'}`}
            >
              <BreadcrumbReactstrap />
              <div className="d-flex align-items-center gap-2">
                <BsCloudCheck size={16} color="#475467" />
                <span
                  onClick={() => navigate(ROUTES.DocumentHistory(document.id))}
                  style={{
                    cursor: 'pointer',
                    color: '#667085',
                    fontFamily: 'DM Sans',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '400',
                  }}
                >
                  Saved {moment(new Date(lastUpdated.updatedAt)).fromNow()} by{' '}
                  {lastUpdated.updatedBy}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.health_section}>
          <BSButton
            onClick={showDocHealth}
            ButtonText="Document Health"
            variant="secondary"
            classList="bg-white text-dark border-0"
          />
        </div>
      </section>
    </div>
  );
}

export default EditorHeader;
