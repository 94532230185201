import * as Yup from 'yup';
import { titleize } from 'helpers';

export const integratonFieldsToObject = (fields, defaultValues) => {
  return fields.reduce((acc, field) => {
    const fieldKey = field.field;
    if (defaultValues && fieldKey === 'key') {
      return acc;
    }

    return {
      ...acc,
      [fieldKey]: defaultValues?.[fieldKey] ?? '',
    };
  }, {});
};

export const integrationFieldsToSchema = (fields, defaultValues) => {
  return fields.reduce((acc, field) => {
    const fieldKey = field.field;
    if (defaultValues && fieldKey === 'key') {
      return {
        ...acc,
        [fieldKey]: Yup.string().optional(),
      };
    }

    return {
      ...acc,
      [fieldKey]: Yup.string().required(`${titleize(fieldKey)} is required`),
    };
  }, {});
};
